import React from "react"
import TemplatePageEbook  from "@components/pageEbook/template"
import { Helmet } from "react-helmet"
import data from "@components/pageEbook/ebooks/ebook12/dataPageEbook12"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Tendencias para contact center: Lo que debes saber en el 2023
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/ebook/tendencias-contact-center/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre las últimas tendencias para contact center y optimiza tu estrategia de atención al cliente. Mantente a la vanguardia en la industria."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Tendencias para contact center: Lo que debes saber en el 2023"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/ebook/tendencias-contact-center/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Ebook/og-imgEbook12.jpg`}
      />
      <meta
        property="og:description"
        content="Descubre las últimas tendencias para contact center y optimiza tu estrategia de atención al cliente. Mantente a la vanguardia en la industria."
      />
    </Helmet>
    <TemplatePageEbook
      ebook="Contact Center"
      location={location}
      typeEbook="ebook12"
      clickHereHref="https://beexcc.com/blog/telemarketing/"
      data={data}
    />
  </div>
)

export default IndexPage
